import {
  ForwardedRef,
  forwardRef,
  memo,
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './PortfolioFilters.module.scss';

import useComponentVisible from '@/hooks/use-detect-outside-click';
import useLayout from '@/hooks/use-layout';

import { CheckmarkSVG, CloseButtonSVG, DropdownSVG } from '@/assets/svgs';

import PortfolioFiltersAccordion, {
  Props as AccordionProps
} from '../PortfolioFiltersAccordion/PortfolioFiltersAccordion';

export type Props = {
  className?: string;
  onFilterChange: (a: { [key: string]: string[] }, b: number) => void;
  categories?: AccordionProps['categories'];
  result?: AccordionProps['result'];
};

function PortfolioFilters(
  { className, onFilterChange, categories, result }: Props,
  ref: ForwardedRef<HTMLDivElement>
) {
  const portalRef = useRef<HTMLDivElement>();
  const { mobile } = useLayout();
  const emptyFilters = { industry: [], partner: [], status: [] };
  const filterRefs = useRef<HTMLButtonElement[]>([]);
  const filterWrapperRef = useRef<HTMLDivElement[]>([]);
  const filterTL = useRef([]) as MutableRefObject<GSAPTimeline[]>;
  const { activeFilter } = useComponentVisible(filterRefs);
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>(emptyFilters);
  const [selectedFiltersNumber, setSelectedFiltersNumber] = useState<number>(0);
  const [isOpenMobileFilters, setOpenMobileFilters] = useState(false);

  useEffect(() => {
    portalRef.current = document.createElement('div');
    const portal = portalRef.current;
    portal.className = 'ModalPortal';
    document.getElementById('portal')?.appendChild(portal);
    return () => {
      document.getElementById('portal')?.removeChild(portal);
    };
  }, []);

  const handleSelectedClick = (category: string, selected: string) => {
    setSelectedFilters((current) => {
      if (current[category].includes(selected)) {
        setSelectedFiltersNumber((number) => number - 1);
        // if clicked filter was active, then remove it from array to deselect it
        return { ...current, [category]: current[category].filter((n) => n !== selected) };
      } else {
        setSelectedFiltersNumber((number) => number + 1);
        // add clicked filter to array
        return { ...current, [category]: [selected, ...current[category]] };
      }
    });
  };

  const handleMobileFiltersExpand = (isOpen: boolean) => {
    setOpenMobileFilters(isOpen);
  };

  const handleClearFiltersClick = () => {
    setSelectedFiltersNumber(0);
    setSelectedFilters(emptyFilters);
  };

  useEffect(() => {
    onFilterChange(selectedFilters, selectedFiltersNumber);
  }, [selectedFilters, selectedFiltersNumber, onFilterChange]);

  useEffect(() => {
    !mobile &&
      filterWrapperRef.current.forEach((ref, index: number) => {
        filterTL.current[index] = gsap
          .timeline()

          .from(ref, {
            autoAlpha: 0,
            duration: 0.2,
            ease: 'ease1'
          })
          .from(
            ref,
            {
              height: 72,
              duration: 0.434
            },
            '-=0.2'
          )
          .from(
            ref.children[0].children,
            {
              autoAlpha: 0,
              duration: 0.3,
              stagger: 0.033,
              ease: 'linear'
            },
            '-=0.434'
          );
      });
  }, [filterWrapperRef, mobile]);

  useEffect(() => {
    filterRefs.current.forEach((_ref, index: number) => {
      const condition = activeFilter >= 0 ? activeFilter !== index : true;
      filterTL.current[index].reversed(condition);
    });
  }, [activeFilter, filterRefs, mobile]);

  return (
    <div className={classnames(styles.PortfolioFilters, className)} ref={ref}>
      {!mobile ? (
        <div className={styles.filtersTopBar}>
          <div className={styles.selectText}>
            Select Industry
            {selectedFiltersNumber > 0 && (
              <button
                className={styles.clearFilters}
                onClick={() => {
                  handleClearFiltersClick();
                }}
              >
                Clear All <CloseButtonSVG />
              </button>
            )}
          </div>
          <div className={styles.filtersWrapper}>
            {categories &&
              Object.keys(categories).map((key, index) => {
                return (
                  <div
                    key={index}
                    className={classnames(styles.filter, {
                      [styles.active]: activeFilter === index
                    })}
                  >
                    <button
                      className={classnames(styles.filterName)}
                      ref={(el: HTMLButtonElement) => (filterRefs.current[index] = el)}
                    >
                      {key}
                      <span className={styles.selectedNumber}>
                        {selectedFilters[key].length > 0 &&
                          selectedFilters[key].length + ' selected'}
                      </span>
                      <div className={styles.dropdown}>
                        <span className={styles.circle}>
                          <DropdownSVG className={styles.svg} />
                        </span>
                      </div>
                    </button>
                    <div
                      className={classnames(styles.filterList)}
                      ref={(el: HTMLDivElement) => (filterWrapperRef.current[index] = el)}
                    >
                      <ul className={styles.filterListWrapper}>
                        {categories![key]
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((cat, index) => {
                            return (
                              <button
                                key={index}
                                className={classnames(styles.filterItem, {
                                  [styles.active]:
                                    selectedFilters.hasOwnProperty(key) &&
                                    selectedFilters[key].includes(cat.slug)
                                })}
                                data-filter={cat.slug}
                                onClick={() => {
                                  handleSelectedClick(key, cat.slug);
                                }}
                              >
                                <span className={styles.circle}>
                                  <CheckmarkSVG className={styles.svg} />
                                </span>
                                {cat.name}
                              </button>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className={styles.mobileFilters}>
          <div className={styles.filterBy} onClick={() => handleMobileFiltersExpand(true)}>
            Filter By
            <button className={styles.dropdown}>
              <span className={styles.circle}>
                <DropdownSVG className={styles.svg} />
              </span>
            </button>
          </div>
          {portalRef.current &&
            categories &&
            isOpenMobileFilters &&
            ReactDOM.createPortal(
              <PortfolioFiltersAccordion
                categories={categories}
                result={result}
                handleMobileFiltersExpand={handleMobileFiltersExpand}
                handleSelectedClick={handleSelectedClick}
                handleClearFiltersClick={handleClearFiltersClick}
                selectedFilters={selectedFilters}
                selectedFiltersNumber={selectedFiltersNumber}
              ></PortfolioFiltersAccordion>,
              portalRef.current
            )}
        </div>
      )}
    </div>
  );
}

export default memo(forwardRef(PortfolioFilters));
