import { memo } from 'react';

import Spacer from '@/components/Spacer/Spacer';

export type Props = {
  height?: string;
};

function BlockSpacer({ height = '4rem' }: Props) {
  return <Spacer height={height} />;
}

export default memo(BlockSpacer);
