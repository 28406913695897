import { memo } from 'react';

import Blocks from '@/blocks/Blocks/Blocks';
import MediaText from '@/components/MediaText/MediaText';

import { Block } from '@/client';

export type Props = {
  className?: string;
  mediaAlt: string;
  mediaUrl: string;
  mediaType: 'image' | 'video';
  mediaId: number;
  innerBlocks: Block[];
  verticalAlignment: 'top' | 'center' | 'bottom';
  mediaPosition: 'left' | 'right';
  imageFill: boolean;
  anchor?: string;
  animation?: 'parallax';
  sizes?: {
    width: number;
    height: number;
  };
};

function BlockMediaText({
  className,
  mediaAlt,
  mediaUrl,
  mediaType,
  verticalAlignment,
  mediaPosition,
  imageFill,
  innerBlocks,
  anchor,
  animation,
  sizes
}: Props) {
  const mediaTextProps = {
    media: {
      alt: mediaAlt,
      src: mediaUrl,
      type: mediaType,
      width: sizes?.width,
      height: sizes?.height
    },
    verticalAlignment,
    mediaPosition,
    imageFill,
    anchor,
    className,
    animation
  };

  return (
    <MediaText {...mediaTextProps}>
      {!!innerBlocks?.length && <Blocks blocks={innerBlocks} />}
    </MediaText>
  );
}

export default memo(BlockMediaText);
