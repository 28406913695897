import React, { memo, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './Columns.module.scss';

export type Props = PropsWithChildren<{
  className?: string;
  columnCount?: number;
  verticalAlignment?: 'top' | 'center' | 'bottom';
  anchor?: string;
}>;

function Columns({ className, columnCount = 2, children, verticalAlignment, anchor }: Props) {
  return (
    <div
      className={classnames(
        styles.Columns,
        className,
        columnCount && styles[`columns-${columnCount}`],
        {
          [styles.alignCenter]: verticalAlignment === 'center',
          [styles.alignBottom]: verticalAlignment === 'bottom'
        }
      )}
      {...(anchor && { id: anchor })}
    >
      {children}
    </div>
  );
}

export default memo(Columns);
