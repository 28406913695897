import React, { memo, useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import isEqual from 'lodash.isequal';

import styles from './PostItem.module.scss';

import Image, { imageLayoutSizes } from '@/components/Image/Image';
import ThemedButton, { ButtonType } from '@/components/ThemedButton/ThemedButton';

import { formatDate } from '@/utils/basic-functions';
import sanitizer from '@/utils/sanitizer';

import { CalendarSVG, ClockSVG, RingsSVG } from '@/assets/svgs';

export type PostItemProps = {
  className?: string;
  date?: string;
  title: string;
  link?: string;
  minutesToRead?: number;
  featuredImage: {
    src?: string;
    alt?: string;
  };
  categories: string[];
  isWide?: boolean;
  isPortfolio?: boolean;
  isInternalLink?: boolean;
};

function PostItem({
  className,
  date,
  title,
  link,
  featuredImage,
  categories,
  minutesToRead,
  isWide = false,
  isPortfolio = false,
  isInternalLink = true
}: PostItemProps) {
  const [hover, setHover] = useState(false);
  const router = useRouter();

  if (!title) {
    return null;
  }

  const formattedDate = date ? formatDate(date) : null;

  return (
    <div
      className={classnames(styles.PostItem, className, {
        [styles.wide]: isWide
      })}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        if (link) {
          if (isInternalLink) {
            router.push(link);
          } else {
            window.open(link, '_blank');
          }
        }
      }}
    >
      <div className={styles.categories}>
        {categories.length !== 0 &&
          categories?.map((category, index) => {
            return (
              <React.Fragment key={index}>
                <span key={index} className={styles.category}>
                  {category}
                </span>
                <span>{index === categories.length - 1 ? '' : ', '}</span>
              </React.Fragment>
            );
          })}
      </div>
      {Boolean(featuredImage?.src) && (
        <Image
          className={styles.image}
          src={featuredImage?.src}
          srcWidths={imageLayoutSizes.quarterBlockWidth}
          alt={featuredImage?.src}
        />
      )}

      <div className={styles.info}>
        {formattedDate ? (
          <>
            <CalendarSVG />
            {formattedDate}
          </>
        ) : null}

        {!isPortfolio && minutesToRead && minutesToRead !== 0 ? (
          <>
            <span className={styles.divider}>|</span>
            <ClockSVG />
            {minutesToRead} min read
          </>
        ) : null}

        {isPortfolio ? (
          <>
            <span className={styles.divider}>|</span>
            <RingsSVG />
            Portfolio
          </>
        ) : null}
      </div>

      {Boolean(title) && (
        <p className={styles.title} dangerouslySetInnerHTML={{ __html: sanitizer(title) }} />
      )}

      {Boolean(link) && (
        <ThemedButton
          className={styles.btn}
          theme={ButtonType.Secondary}
          text="Read More"
          url={link}
          active={hover}
          j3IsInternalURL={isInternalLink}
        ></ThemedButton>
      )}
    </div>
  );
}

export default memo(PostItem, (prevProps: PostItemProps, nextProps: PostItemProps) =>
  isEqual(prevProps, nextProps)
);
