import { memo, ReactNode } from 'react';

import RichText from '@/components/RichText/RichText';

import sanitizer from '@/utils/sanitizer';

import { Block } from '@/client';

interface Props {
  className?: string;
  ordered?: boolean;
  values: string & ReactNode;
  innerBlocks?: Block[];
}

function BlockList({ className, ordered, values, innerBlocks }: Props) {
  const innerBlocksData = innerBlocks
    ?.map((listItem) => {
      return listItem?.saveContent ? sanitizer(listItem.saveContent) : '';
    })
    .join('');

  const childrenData = values || innerBlocksData || '';

  return (
    <RichText className={className} tag={ordered ? 'ol' : 'ul'}>
      {childrenData}
    </RichText>
  );
}

export default memo(BlockList);
