// @ts-nocheck TODO: fix typescript errors
import { memo, MutableRefObject, useEffect, useRef } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './CompanyHandler.module.scss';

import CompanyItem from '@/components/CompanyItem/CompanyItem';
import PostGrid from '@/components/PostGrid/PostGrid';

import { client } from '@/client';

export type Props = {
  className?: string;
  query: {
    perPage: string;
    search: string;
    taxQuery: {
      category: [];
      post_tag: [];
    };
    orderBy: string;
    order: string;
    selected: [];
  };
};

function CompanyHandler({ className, query }: Props) {
  const { useQuery } = client;
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const queryObj = useQuery();
  const queries = queryObj.companies({
    first: parseInt(query?.perPage),
    where: {
      search: query?.search,
      in: query?.selected ?? []
    }
  });

  const isLoading = queryObj.$state.isLoading;

  const companies = queries?.nodes?.map((companyItem) => ({
    excerpt: companyItem?.excerpt(),
    name: companyItem?.title(),
    listingImage: {
      src: companyItem?.companies?.listingImage?.sourceUrl(),
      alt: companyItem?.companies?.listingImage?.altText
    },
    logo: companyItem?.companies?.logo?.sourceUrl(),
    link: companyItem?.uri
  }));

  useEffect(() => {
    const items = Array.from(containerRef.current.children);

    if (!items.length || isLoading) {
      return;
    } else {
      gsap.set(items, { opacity: 0, y: 20 });
    }

    const timeline = gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 70%',
          toggleActions: 'play none none none'
        }
      })
      .fadeIn(items, { duration: 0.734, stagger: 0.067 }, 0)
      .translateIn(items, { duration: 0.5, stagger: 0.067 }, 0);

    return () => {
      timeline?.scrollTrigger?.kill();
      timeline?.kill();
    };
  }, [isLoading]);

  return (
    <div className={classnames(styles.CompanyHandler, className)}>
      <PostGrid isCompaniesStyle ref={containerRef}>
        {companies
          ?.filter((companyItem) => Boolean(companyItem.name))
          ?.map((companyItem, index) => {
            // @ts-ignore
            return <CompanyItem {...companyItem} key={index} />;
          })}
      </PostGrid>
    </div>
  );
}

export default memo(CompanyHandler);
