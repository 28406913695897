// @ts-nocheck TODO: fix typescript errors
import { memo } from 'react';

import FilterBar from '@/components/FilterBar/FilterBar';

import { client } from '@/client';

type Props = {
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  activeFilter: number;
};

function PressItemsFilter({ handleClick, activeFilter }: Props) {
  const { useQuery } = client;
  const query = useQuery();

  const categoryItems = query.pressCategories({ first: 100 }).nodes.map((item) => ({
    name: item?.name,
    id: item?.slug,
    count: item?.count
  }));

  const items = query.pressPosts({ first: 100 }).nodes.map((item) => ({
    name: item?.title
  }));

  const totalCount = items.length;

  const filteredItems = categoryItems
    .map(({ name, count, id }) => ({ name, count, id }))
    .filter(({ count }) => count > 0);

  const allItems = [{ name: 'all', count: totalCount, id: 0 }, ...filteredItems];
  const activeItem = allItems.find((item) => item.id === activeFilter);

  function handleCategoryClick(e, name, id) {
    handleClick(e, id);
  }

  if (items?.length) {
    if (!items[0].name) {
      return null;
    }
  } else {
    if (items?.length === 0) {
      return null;
    }
  }

  return (
    <FilterBar
      items={allItems}
      onItemClick={handleCategoryClick}
      activeItem={activeItem.name}
      mainItem="all"
      isLeftAligned
    />
  );
}

export default memo(PressItemsFilter);
