import { memo, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './Accordion.module.scss';

export type Props = PropsWithChildren<{
  className?: string;
}>;

function Accordion({ className, children }: Props) {
  return <div className={classnames(styles.Accordion, className)}>{children}</div>;
}

export default memo(Accordion);
