import { memo, useEffect, useRef } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './Separator.module.scss';

import useLayout from '@/hooks/use-layout';

export type Props = {
  className?: string;
  isTopAbsolute?: boolean;
  isBottomAbsolute?: boolean;
  isHalfScreen?: boolean;
};

function Separator({
  className,
  isTopAbsolute = false,
  isBottomAbsolute = false,
  isHalfScreen = false
}: Props) {
  const elementRef = useRef(null);
  const { mobile } = useLayout();

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const duration = mobile || isHalfScreen ? 0.5 : 1;

    gsap.set(elementRef.current, { scaleX: 0 });

    const animation = gsap
      .timeline({
        scrollTrigger: {
          trigger: elementRef.current,
          start: 'top 70%'
        }
      })
      .expandLine(
        elementRef.current,
        {
          duration: duration
        },
        0
      );

    return () => {
      animation?.scrollTrigger?.kill();
      animation?.kill();
    };
  }, [mobile, isHalfScreen]);

  return (
    <hr
      ref={elementRef}
      className={classnames(styles.Separator, className, {
        [styles.isTopAbsolute]: isTopAbsolute,
        [styles.isBottomAbsolute]: isBottomAbsolute
      })}
    />
  );
}

export default memo(Separator);
