// @ts-nocheck TODO: fix typescript errors
import { memo } from 'react';
import classnames from 'classnames';

import styles from './PressItemHandler.module.scss';

import MediaText from '@/components/MediaText/MediaText';

import { RingsSVG } from '@/assets/svgs';

import { client } from '@/client';

import ThemedButton, { ButtonType } from '../ThemedButton/ThemedButton';

export type Props = {
  className?: string;
  query: {
    perPage: string;
    taxQuery: {
      category: [];
      post_tag: [];
    };
    keyword: string;
    search: string;
    orderBy: string;
    order: string;
    selected: [];
  };
};

function PressItemHandler({ className, query }: Props) {
  const { useQuery } = client;

  const pressPosts = useQuery().pressPosts({
    first: parseInt(query?.perPage),
    where: {
      categoryIn: query?.taxQuery?.category,
      tagIn: query?.taxQuery?.post_tag,
      search: query?.search,
      orderby: {
        fields: {
          field: query.orderBy.toUpperCase(),
          order: query.order.toUpperCase()
        }
      },
      in: !query?.search && query?.selected ? query?.selected : []
    }
  });

  const postData = pressPosts?.nodes?.[0];
  const featuredImage = postData?.featuredImage?.node;

  const postProps = {
    title: postData?.title(),
    link: postData?.acfPress?.externalLink,
    featuredImage: {
      src: featuredImage?.sourceUrl() ?? '',
      alt: featuredImage?.altText ?? '',
      type: featuredImage?.mediaType ?? '',
      width: featuredImage?.mediaDetails?.width ?? 0,
      height: featuredImage?.mediaDetails?.height ?? 0
    },
    acfPress: {
      source: postData?.acfPress?.source,
      type: postData?.acfPress?.type
    }
  };

  return (
    <div
      className={classnames(styles.PressItemHandler, className, {
        [styles.isLoading]: !(postProps && postProps?.title)
      })}
    >
      {postProps && postProps?.title ? (
        <MediaText
          className={classnames(styles.mediaText, 'no-desktop-border-top')}
          media={postProps.featuredImage}
        >
          <h4 className={styles.featured}>In The News</h4>
          <p className={styles.author}>
            {postProps.acfPress.source}
            {postProps.acfPress.type ? (
              <span className={styles.type}>
                <span className={styles.divider}>|</span>
                <RingsSVG />
                {postProps.acfPress.type}
              </span>
            ) : null}
          </p>
          <h2 className={styles.title}>{postProps.title}</h2>
          <ThemedButton
            theme={ButtonType.Primary}
            text="Read More"
            url={postProps.link}
            j3IsInternalURL={false}
          />
        </MediaText>
      ) : null}
    </div>
  );
}

export default memo(PressItemHandler);
