import { memo } from 'react';
import classnames from 'classnames';

import styles from './AccordionTrigger.module.scss';

import { DropdownSVG } from '@/assets/svgs';

export type Props = {
  className?: string;
  isOpen: boolean;
  hasDropdownSvg?: boolean;
  onClick?: () => void;
};

function AccordionTrigger({ className, isOpen, hasDropdownSvg = false, onClick }: Props) {
  return (
    <button
      className={classnames(styles.AccordionTrigger, className, {
        [styles.isOpen]: isOpen,
        [styles.hasDropdownSvg]: hasDropdownSvg
      })}
      onClick={onClick}
    >
      {hasDropdownSvg ? (
        <DropdownSVG className={styles.svg} />
      ) : (
        <>
          <span />
          <span />
        </>
      )}
    </button>
  );
}

export default memo(AccordionTrigger);
