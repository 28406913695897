import { memo } from 'react';
import classnames from 'classnames';

import styles from './PlayButton.module.scss';

import { PlaySVG } from '@/assets/svgs';

export type Props = {
  className?: string;
  onClick(): void;
  text?: 'Play Reel';
};

function PlayButton({ className, onClick, text }: Props) {
  return (
    <button onClick={onClick} className={classnames('PlayButton', styles.PlayButton, className)}>
      {text}
      <span className={styles.circle}>
        <PlaySVG className={styles.svg} />
      </span>
    </button>
  );
}

export default memo(PlayButton);
