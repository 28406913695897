import { memo } from 'react';
import classnames from 'classnames';

import styles from './Gallery.module.scss';

import Image, { imageLayoutSizes } from '@/components/Image/Image';

export type Props = {
  className?: string;
  images?: {
    alt: string;
    url: string;
    sizes: {
      width: number;
      height: number;
    };
    caption: string;
  }[];
};

function Gallery({ className, images }: Props) {
  return (
    <div className={classnames(styles.Gallery, className)}>
      {images?.map((image, index) => (
        <div key={index}>
          <Image
            className={styles.image}
            src={image.url}
            alt={image.alt}
            srcWidths={imageLayoutSizes.halfBlockWidth}
            width={image.sizes.width}
            height={image.sizes.height}
          />
          {/* <p className={styles.caption}>{image.caption}</p> */}
          <p className={styles.caption}>Lorem Ipsum</p>
        </div>
      ))}
    </div>
  );
}

export default memo(Gallery);
