import { memo } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import styles from './BackButton.module.scss';

import { ArrowRightSVG } from '@/assets/svgs';

export type Props = {
  className?: string;
  text?: string;
  url?: string;
  withBorder?: boolean;
};

function BackButton({ className, text = 'Back', url = '/', withBorder = false }: Props) {
  return (
    <Link href={Boolean(url) ? url : '/'}>
      <a
        className={classnames('BackButton', styles.BackButton, className, {
          [styles.withBorder]: withBorder
        })}
      >
        <span className={styles.circle}>
          <ArrowRightSVG className={styles.svg} />
        </span>
        {text}
      </a>
    </Link>
  );
}

export default memo(BackButton);
