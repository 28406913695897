/* eslint-disable sonarjs/cognitive-complexity */
import { ForwardedRef, forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import styles from './Image.module.scss';

import { getRandomInt, getSteppedRange } from '@/utils/basic-functions';
import useCombinedRefs from '@/hooks/use-combined-refs';

export type Props = {
  className?: string;
  src?: string;
  srcWidths: number[];
  alt?: string;
  loadingType?: 'lazy' | 'eager';
  width?: number;
  height?: number;
  caption?: string;
  align?: 'full' | 'wide' | 'left' | 'right' | 'center';
  fillContainer?: boolean;
  quality?: number;
  useParallax?: boolean;
  animateOnScroll?: boolean;
};

const isDev = process.env.NODE_ENV !== 'production';
const isBrowser = typeof window !== 'undefined';

// URLs
const modifiedImgSrcMap: { [key: string]: string } = {};
const jetpackRegexp = new RegExp(/^(https:\/\/)i[0-2]\.wp\.com/);
const splitWpSrc = (process.env.NEXT_PUBLIC_WORDPRESS_URL || '').split('://')[1];

// sizes
const minSize = 320;
const maxSize = 1600;
const step = 160;
export const imageLayoutSizes = {
  viewport: [...getSteppedRange(minSize, maxSize, step), 1920], // stretches to the edges of viewport e.g. CoverHero
  fullBlockWidth: getSteppedRange(minSize, maxSize, step), // takes up-to 100% of BlockGroup width
  halfBlockWidth: getSteppedRange(minSize, maxSize * 0.5, step), // takes up-to 1/2 of BlockGroup width
  thirdBlockWidth: getSteppedRange(minSize, maxSize * 0.3, step), // takes up-to 1/3 of BlockGroup width
  quarterBlockWidth: getSteppedRange(minSize, maxSize * 0.25, step) // takes up-to 1/4 of BlockGroup width
};

const DEFAULT_QUALITY_IMAGE = 90;

const Image = (
  {
    className,
    src = '',
    srcWidths,
    alt,
    loadingType = 'lazy',
    width,
    height,
    caption,
    align,
    quality = DEFAULT_QUALITY_IMAGE,
    fillContainer = false,
    useParallax = false,
    animateOnScroll = false
  }: Props,
  ref: ForwardedRef<HTMLImageElement>
) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const figureRef = useRef<HTMLDivElement>(null);
  const combinedRef = useCombinedRefs(ref, imageRef);
  const [loaded, setLoaded] = useState(false);

  if (!src) {
    console.warn(`Image is missing 'src' therefore null is returned`);
  }

  if (!srcWidths) {
    console.warn(
      `Image with src: ${src} has is missing required "srcWidths" prop. 'srcSet' will default to [${imageLayoutSizes.fullBlockWidth}]`
    );
  }

  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  useEffect(() => {
    if (!useParallax || !imageRef.current) {
      return;
    }

    const timeline = gsap
      .timeline({
        scrollTrigger: {
          trigger: figureRef.current,
          scrub: true,
          start: 'top 80%',
          end: 'bottom 20%'
        }
      })
      .fromTo(imageRef.current, { y: '-10%' }, { y: '0%' });

    return () => {
      timeline?.scrollTrigger?.kill(true);
      timeline?.kill();
    };
  }, [useParallax]);

  useEffect(() => {
    if (!imageRef.current || !animateOnScroll) {
      return;
    }

    const timeline = gsap
      .timeline({
        scrollTrigger: {
          trigger: imageRef.current,
          start: 'top 80%'
        }
      })
      .setupFadeIn(imageRef.current, { y: 0 }, 0)
      .fadeIn(imageRef.current, { duration: 0.5 }, 0.3);

    return () => {
      timeline?.scrollTrigger?.kill();
      timeline?.kill();
    };
  }, [animateOnScroll]);

  useEffect(() => {
    const img = imageRef.current!;

    const handleLoad = () => {
      img?.removeEventListener('load', handleLoad);
      img?.removeEventListener('loadedmetadata', handleLoad);
      setLoaded(true);

      ScrollTrigger.refresh();
    };

    if (!loaded && img) {
      const complete = Boolean(img?.complete);
      if (complete) {
        setLoaded(true);
      } else {
        img?.addEventListener('load', handleLoad);
        img?.addEventListener('loadedmetadata', handleLoad);
      }
    }
    return () => {
      img?.removeEventListener('load', handleLoad);
      img?.removeEventListener('loadedmetadata', handleLoad);
    };
  }, [loaded]);

  const imgSrc = useMemo(() => {
    let modifiedSrc = src;

    // check if image source is a Jetpack Photon URL
    if (!jetpackRegexp.test(src)) {
      // check if there's already image in the global map with the current `src` key and replaced URL as  its value
      if (modifiedImgSrcMap[src]) {
        return modifiedImgSrcMap[src];
      }

      // replace source with Jetpack Photon URL
      const [, ...rest] = src.split('.com');

      // set local server instance to be always 0 to avoid warnings about server and client `src` mismatch
      const serverInstance = isDev ? 0 : getRandomInt(0, 2);
      modifiedSrc = `https://i${serverInstance}.wp.com/${splitWpSrc}${rest.join('')}`;

      // store modified URL in the global map to avoid re-fetching the same image key from a different server (hence server randomization)
      modifiedImgSrcMap[src] = modifiedSrc;
    }

    return modifiedSrc;
  }, [src]);

  const imgSrcWidths = useMemo(() => {
    return srcWidths || imageLayoutSizes.fullBlockWidth;
  }, [srcWidths]);

  const optimizedSrcSet = useMemo(() => {
    if (!imgSrcWidths.length) {
      return undefined;
    }

    return imgSrcWidths
      .map((w) => {
        // append params to the end of existing URL params or add the whole query to URL
        const paramsJoint = /\?/.test(imgSrc) ? '&' : '?';

        // set pixelDensity to be 2 until image is hydrated.
        // Note: images loaded on mount (above the fold) will have server value of `srcSet`attribute
        //       that won't hydrate root until next navigation to the page (see https://reactjs.org/docs/react-dom.html#hydrate)
        //       therefore it's safer to have x2 as it targets the majority of devices.
        const pixelDensity = isBrowser ? Math.max(window.devicePixelRatio, 2) : !isHydrated ? 2 : 1;

        const opts: { [key: string]: number } = {
          w: w * pixelDensity,
          quality
        };
        const queryString = Object.keys(opts)
          .filter(Boolean)
          .map((key) => `${key}=${opts[key]}`)
          .join('&');

        return `${imgSrc}${paramsJoint}${queryString} ${w}w`;
      })
      .join(', ');
  }, [imgSrcWidths, imgSrc, quality, isHydrated]);

  return src ? (
    <figure
      className={classnames(styles.Image, className, {
        [styles.fillContainer]: fillContainer
      })}
      ref={figureRef}
    >
      <img
        className={classnames({
          [styles[`${align}`]]: align,
          [styles.parallax]: useParallax
        })}
        ref={combinedRef}
        src={imgSrc}
        srcSet={optimizedSrcSet}
        alt={alt}
        decoding="async"
        loading={loadingType}
        {...(width && height
          ? {
              style: {
                aspectRatio: `${width / height}`
              }
            }
          : {})}
      />
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  ) : null;
};

export default memo(forwardRef(Image));
