import { memo } from 'react';
import classnames from 'classnames';

import styles from './LoadingItem.module.scss';

export type Props = {
  className?: string;
};

function LoadingItem({ className }: Props) {
  return (
    <div className={classnames(styles.LoadingItem, className)} aria-hidden>
      <div className={styles.categories}></div>
      <div className={styles.image}></div>
      <div className={styles.info}>
        <div />
        <span className={styles.divider}>|</span>
        <div />
      </div>
      <div className={styles.title}></div>
      <div className={styles.btn}></div>
    </div>
  );
}

export default memo(LoadingItem);
