import { memo } from 'react';

import Quote from '@/components/Quote/Quote';

import { Block } from '@/client';

export type Props = {
  content?: string;
  className?: string;
  value: string;
  innerBlocks: Block[];
};

function BlockQuote({ content, className, value, innerBlocks }: Props) {
  const quote = value || innerBlocks[0]?.originalContent || innerBlocks[0]?.saveContent;

  if (!quote) {
    return null;
  }

  return <Quote className={className} quote={quote} author={content} />;
}

export default memo(BlockQuote);
