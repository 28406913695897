import { memo } from 'react';

import Image, { imageLayoutSizes } from '@/components/Image/Image';

export interface Props {
  alt?: string;
  caption?: string;
  url?: string;
  className?: string;
  align?: 'full' | 'wide' | 'left' | 'right' | 'center';
  animation?: 'parallax';
  sizes?: {
    width: number;
    height: number;
  };
}

function BlockImage({ alt, caption, url, className, align, animation, sizes }: Props) {
  return (
    <Image
      className={className}
      src={url}
      alt={alt}
      caption={caption}
      align={align}
      animateOnScroll={true}
      width={sizes?.width}
      height={sizes?.height}
      srcWidths={imageLayoutSizes.fullBlockWidth}
      useParallax={animation?.includes('parallax')}
    />
  );
}

export default memo(BlockImage);
