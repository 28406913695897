import { memo } from 'react';
import classnames from 'classnames';

import styles from './BlockGroup.module.scss';

import Blocks from '@/blocks/Blocks/Blocks';

import { Block } from '@/client';

export interface Props {
  className: string;
  tagName?: string;
  anchor?: string;
  backgroundColor?: string;
  innerBlocks?: Block[];
}

function BlockGroup({ className, tagName, anchor, backgroundColor, innerBlocks }: Props) {
  const Tag = tagName as keyof JSX.IntrinsicElements;

  return (
    <Tag
      className={classnames(styles.BlockGroup, className, {
        [`has-${backgroundColor}-background-color`]: backgroundColor,
        'has-background': backgroundColor
      })}
      {...(anchor ? { id: anchor } : {})}
    >
      {!!innerBlocks?.length && <Blocks blocks={innerBlocks} />}
    </Tag>
  );
}

export default memo(BlockGroup);
