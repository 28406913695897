// @ts-nocheck TODO: fix typescript when fetching graphql data
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import styles from './TeamGrid.module.scss';

import FilterBar from '@/components/FilterBar/FilterBar';
import Image, { imageLayoutSizes } from '@/components/Image/Image';

import { ArrowRightSVG } from '@/assets/svgs';

export type Props = {
  className?: string;
};

const mainCategory = 'all';
const mainCategoryName = 'Team Eclipse';

function TeamGrid({ className, teamMembers }: Props) {
  const gridContainer = useRef(null);

  const [activeCategory, setActiveCategory] = useState(mainCategory);

  const triggerCategory = useCallback(
    (e, category: string) => {
      setActiveCategory(category);
    },
    [setActiveCategory]
  );

  const categories = teamMembers?.reduce((acc, item) => {
    const category = item.category;
    const categoryIndex = acc.findIndex((item) => item.name === category);
    if (categoryIndex === -1) {
      acc.push({ name: category, count: 1 });
    } else {
      acc[categoryIndex].count += 1;
    }
    return acc;
  }, []);

  const items = [...categories, { name: mainCategory, count: teamMembers?.length }];

  useEffect(() => {
    const items = Array.from(gridContainer.current.children);

    if (!items.length) {
      return;
    } else {
      gsap.set(items, { opacity: 0, y: 20, pointerEvents: 'none' });
    }

    const batchList = ScrollTrigger.batch(items, {
      start: 'top 70%',
      onEnter: (batch) => {
        gsap
          .timeline()
          .fadeIn(batch, { stagger: 0.067, duration: 0.734 }, 0.1)
          .translateIn(batch, { stagger: 0.067, duration: 0.5, clearProps: 'pointerEvents' }, 0.1);
      }
    });

    return () => {
      batchList?.forEach((trigger) => trigger.kill());
    };
  }, [activeCategory]);

  return (
    <div className={classnames(styles.TeamGrid, className)}>
      <FilterBar
        onItemClick={triggerCategory}
        activeItem={activeCategory}
        mainItem={mainCategory}
        mainItemName={mainCategoryName}
        items={items}
      />

      <div className={styles.grid} ref={gridContainer}>
        {teamMembers
          ?.filter(
            (person) => person.category === activeCategory || activeCategory === mainCategory
          )
          ?.map((person, index) => {
            return (
              <Link key={index} href={person.url ?? '#'}>
                <a className={styles.item} data-category={person.category}>
                  <Image
                    className={styles.profilePic}
                    src={person.image.src}
                    srcWidths={imageLayoutSizes.quarterBlockWidth}
                    alt={person.image.alt}
                  />
                  <p className={styles.position}>{person.position}</p>
                  <p className={styles.name}>
                    {person.name}
                    <ArrowRightSVG className={styles.svg} />
                  </p>
                  <span className={styles.rollover}></span>
                </a>
              </Link>
            );
          })}
      </div>
    </div>
  );
}

export default memo(TeamGrid);
