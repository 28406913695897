import { memo } from 'react';

import { Block } from '@/client';

import displayBlock from './displayBlock';

export interface Props {
  blocks?: Block[];
}

function Blocks({ blocks }: Props) {
  return (
    <>
      {
        // NOTE: If there are blocks, loop over and display.
        blocks?.map((block) => {
          return displayBlock(block);
        })
      }
    </>
  );
}

export default memo(Blocks);
