import { ForwardedRef, forwardRef, memo, useLayoutEffect, useRef } from 'react';
import { useReducedMotion } from '@mantine/hooks';
import classnames from 'classnames';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import styles from './InlineVideo.module.scss';

import useCombinedRefs from '@/hooks/use-combined-refs';

export type Props = {
  className?: string;
  src?: string;
  width?: number;
  height?: number;
  title?: string;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  controls?: boolean;
};

const InlineVideo = (
  {
    className,
    src,
    width,
    height,
    title,
    autoPlay = false,
    muted = true,
    loop = false,
    controls = true
  }: Props,
  ref: ForwardedRef<HTMLVideoElement>
) => {
  const reducedMotion = useReducedMotion();
  const videoRef = useRef<HTMLVideoElement>(null);
  const combinedRef = useCombinedRefs(ref, videoRef);
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (videoRef.current && !reducedMotion) {
      ScrollTrigger.create({
        trigger: videoRef.current,
        start: 'top bottom',
        end: 'bottom top',
        onEnter: () => videoRef.current?.play(),
        onEnterBack: () => videoRef.current?.play(),
        onLeave: () => videoRef.current?.pause(),
        onLeaveBack: () => videoRef.current?.pause()
      });
    }
  }, [reducedMotion]);

  if (!src) {
    return null;
  }

  return (
    <video
      className={classnames(styles.InlineVideo, className)}
      ref={combinedRef}
      src={src}
      title={title}
      autoPlay={reducedMotion ? false : autoPlay}
      muted={muted}
      loop={loop}
      controls={reducedMotion ? true : controls}
      playsInline
      {...(width && height
        ? {
            style: {
              aspectRatio: `${width / height}`
            }
          }
        : {})}
    >
      <source src={src} />
    </video>
  );
};

export default memo(forwardRef(InlineVideo));
