import { memo, MutableRefObject, PropsWithChildren, useEffect, useRef } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './MediaText.module.scss';

import Image, { imageLayoutSizes } from '@/components/Image/Image';
import InlineVideo from '@/components/InlineVideo/InlineVideo';
import Separator from '@/components/Separator/Separator';

export type Props = PropsWithChildren<{
  className?: string;
  media: {
    alt?: string;
    src: string;
    width?: number;
    height?: number;
    type?: string | 'image' | 'video';
  };
  verticalAlignment?: 'top' | 'center' | 'bottom';
  mediaPosition?: 'left' | 'right';
  imageFill?: boolean;
  anchor?: string;
  animation?: 'parallax';
}>;

function MediaText({
  className,
  children,
  media,
  verticalAlignment,
  mediaPosition,
  imageFill,
  anchor,
  animation
}: Props) {
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const timeline = gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 70%'
        }
      })
      .setupFadeIn(containerRef.current, { y: 0 }, 0)
      .fadeIn(containerRef.current, {}, 0.3);

    return () => {
      timeline?.scrollTrigger?.kill();
      timeline?.kill();
    };
  }, []);

  return (
    <div
      className={classnames(styles.MediaText, className, {
        [styles.flipOrder]: mediaPosition === 'left',
        [styles.alignCenter]: verticalAlignment === 'center',
        [styles.alignBottom]: verticalAlignment === 'bottom'
      })}
      ref={containerRef}
      {...(anchor && { id: anchor })}
    >
      <div className={styles.textContainer}>
        <Separator isTopAbsolute className={styles.topBorder} />
        {children}
        <Separator isBottomAbsolute className={styles.bottomBorder} />
      </div>
      <div className={styles.mediaContainer}>
        {media?.type === 'image' && (
          <Image
            className={styles.image}
            {...media}
            fillContainer={imageFill}
            alt={media?.alt}
            srcWidths={imageLayoutSizes.halfBlockWidth}
            useParallax={animation?.includes('parallax')}
          />
        )}
        {media?.type === 'video' && <InlineVideo src={media?.src} muted={false}></InlineVideo>}
      </div>
    </div>
  );
}

export default memo(MediaText);
