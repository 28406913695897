import { memo } from 'react';
import classnames from 'classnames';

import styles from './SliderButton.module.scss';

import { ArrowRightSVG } from '@/assets/svgs';

export type Props = {
  className?: string;
  controls: {
    onNextSlide: () => void;
    onPreviousSlide: () => void;
    activeSlide: number;
    totalSlides: number;
  };
};

function SliderButton({
  className,
  controls: { onNextSlide, onPreviousSlide, activeSlide, totalSlides }
}: Props) {
  const activeSlideString = activeSlide.toString().padStart(2, '0');
  const totalSlidesString = totalSlides.toString().padStart(2, '0');

  return (
    <div className={classnames(styles.SliderButton, className)}>
      <button
        onClick={onPreviousSlide}
        className={classnames(styles.button, styles.previous)}
        disabled={activeSlide === 1}
      >
        <ArrowRightSVG />
      </button>
      <div className={styles.text}>
        <span className={classnames(styles.number, styles.active)}>{activeSlideString}</span>
        <span>|</span>
        <span className={styles.number}>{totalSlidesString}</span>
      </div>
      <button
        onClick={onNextSlide}
        className={classnames(styles.button, styles.next)}
        disabled={activeSlide === totalSlides}
      >
        <ArrowRightSVG />
      </button>
    </div>
  );
}

export default memo(SliderButton);
