import { memo, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './RichText.module.scss';

import parseHtml from '@/utils/parser';

export type Props = PropsWithChildren<{
  className?: string;
  align?: 'left' | 'center' | 'right';
  textColor?: string;
  tag?: string;
  children: string;
  j3Hyphen?: boolean;
}>;

function RichText({ children, className, align, textColor, tag = 'p', j3Hyphen }: Props) {
  const Tag = `${tag}` as keyof JSX.IntrinsicElements;
  const tagClassName = tag;

  return (
    <Tag
      className={classnames(styles.RichText, className, styles?.[tagClassName], {
        [`has-text-align-${align}`]: align,
        [`has-${textColor}-color`]: textColor,
        'line-indicator': j3Hyphen
      })}
    >
      {parseHtml(children ?? '')}
    </Tag>
  );
}

export default memo(RichText);
