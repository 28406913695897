import { memo } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import isEqual from 'lodash.isequal';

import styles from './CompanyItem.module.scss';

import AsyncImage from '@/components/AsyncImage/AsyncImage';
import Image, { imageLayoutSizes } from '@/components/Image/Image';
import ThemedButton, { ButtonType } from '@/components/ThemedButton/ThemedButton';

import { cleanupHTMLTags } from '@/utils/basic-functions';
import sanitizer from '@/utils/sanitizer';

export type CompanyItemProps = {
  className?: string;
  excerpt?: string;
  name?: string;
  listingImage: {
    src?: string;
    alt?: string;
  };
  logo?: string;
  link?: string;
};

function CompanyItem({
  className,
  excerpt,
  name,
  listingImage: { src, alt },
  logo,
  link
}: CompanyItemProps) {
  const router = useRouter();

  return (
    <div
      className={classnames(styles.CompanyItem, className, {
        [styles.inverse]: !src
      })}
      onClick={() => link && router.push(link)}
    >
      <div className={styles.container}>
        {Boolean(logo) && (
          <AsyncImage src={logo} className={styles.imageLogo} alt={`${name}'s company logo`} />
        )}
        {Boolean(excerpt) && (
          <h2
            className={styles.excerpt}
            dangerouslySetInnerHTML={{ __html: sanitizer(cleanupHTMLTags(excerpt)) }}
          ></h2>
        )}
      </div>

      {Boolean(link) && (
        <ThemedButton
          className={styles.btn}
          theme={ButtonType.Secondary}
          text="Learn More"
          url={link}
          j3IsInternalURL={true}
        />
      )}

      {Boolean(src) && (
        <Image
          className={styles.image}
          src={src}
          alt={alt}
          srcWidths={imageLayoutSizes.thirdBlockWidth}
        />
      )}
      {Boolean(src) && <div className={styles.shade} />}
    </div>
  );
}

export default memo(CompanyItem, (prevProps: CompanyItemProps, nextProps: CompanyItemProps) =>
  isEqual(prevProps, nextProps)
);
