import React, { forwardRef, memo } from 'react';
import classnames from 'classnames';

import styles from './PostGrid.module.scss';

export type Props = {
  className?: string;
  isCompaniesStyle?: boolean;
  children: React.ReactNode;
  disableVariableGrid?: boolean;
};

const PostGrid = forwardRef<HTMLDivElement, Props>(
  ({ className, isCompaniesStyle = false, children, disableVariableGrid = false }, ref) => {
    const count = React.Children.count(children);

    return (
      <div
        className={classnames('PostGrid', styles.PostGrid, className, [styles[`count-${count}`]], {
          [styles.overFour]: count >= 4 && !isCompaniesStyle,
          [styles.disableVariableGrid]: disableVariableGrid,
          [styles.isCompaniesStyle]: isCompaniesStyle
        })}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

PostGrid.displayName = 'PostGrid';

export default memo(PostGrid);
