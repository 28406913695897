import { memo, useEffect, useRef, useState } from 'react';
import router from 'next/router';
import { device } from '@jam3/detect';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './PortfolioItem.module.scss';

import AccordionTrigger from '@/components/AccordionTrigger/AccordionTrigger';
import AsyncImage from '@/components/AsyncImage/AsyncImage';

import sanitizer from '@/utils/sanitizer';

import { CategorySVG, CheckmarkSVG } from '@/assets/svgs';

import ThemedButton, { ButtonType } from '../ThemedButton/ThemedButton';

export type Props = {
  className?: string;
  name?: string;
  logo?: {
    src?: string;
    alt?: string;
  };
  text?: string;
  categories: {
    industry: { slug: string; name: string };
    status: { slug: string; name: string }[];
  };
  link?: string;
  stockTicker?: string;
};

function PortfolioItem({ className, logo, text, categories, link, stockTicker }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const q = gsap.utils.selector(cardRef);

    if (isOpen) {
      gsap.set(q('.bg'), {
        autoAlpha: 0
      });
      gsap.set([q('.text'), q('.button')], {
        autoAlpha: 0,
        y: 20
      });
      gsap.to(q('.bg'), {
        autoAlpha: 1,
        ease: 'defaultEase'
      });
      gsap.to([q('.text'), q('.button')], {
        autoAlpha: 1,
        y: 0,
        ease: 'defaultEase'
      });
    } else {
      gsap.to([q('.text'), q('.button'), q('.bg')], {
        autoAlpha: 0
      });
    }
  }, [isOpen]);

  return (
    <div
      className={classnames(styles.PortfolioItem, className, { [styles.isOpen]: isOpen })}
      data-category={`industry-${categories.industry?.slug}`}
    >
      <div
        className={classnames(styles.container)}
        onMouseEnter={() => {
          !device.touch && setIsOpen(true);
        }}
        onMouseLeave={() => {
          !device.touch && setIsOpen(false);
        }}
      >
        <div className={styles.front}>
          <AsyncImage className={classnames(styles.logo, 'logo')} src={logo?.src} alt={logo?.alt} />
          <div className={styles.topWrapper}>
            {Array.isArray(categories?.status) && categories?.status.length > 0 && (
              <div className={styles.cat}>
                <>
                  <span className={styles.circle}>
                    <CategorySVG />
                  </span>
                  {categories?.status?.map((status, index) => {
                    return (
                      <p className={styles.catName} key={index}>
                        {status.name}
                        {Array.isArray(categories?.status) &&
                          index + 1 < categories?.status?.length &&
                          ', '}
                      </p>
                    );
                  })}
                </>
              </div>
            )}

            {stockTicker && (
              <div className={classnames(styles.cat, styles.tickerWrapper)}>
                <span className={styles.circle}>
                  <CheckmarkSVG />
                </span>
                <p className={styles.ticker}>{stockTicker}</p>
              </div>
            )}
          </div>
        </div>
        <div className={styles.back} ref={cardRef}>
          {!device.touch && (
            <button
              className={styles.linkOverlay}
              onClick={() => {
                if (link) {
                  router.push(link);
                }
              }}
            />
          )}
          <span className={classnames(styles.bg, 'bg')} />
          <p
            className={classnames(styles.text, 'text')}
            dangerouslySetInnerHTML={{ __html: sanitizer(text ?? '') }}
          />
          <div className={classnames(styles.button, 'button', { [styles.hidden]: !device.touch })}>
            <ThemedButton
              theme={ButtonType.Secondary}
              text="Learn More"
              url={link ?? ''}
              j3IsInternalURL={true}
            ></ThemedButton>
          </div>

          <div className={styles.bottomWrapper}>
            {categories?.industry.hasOwnProperty('name') && (
              <div className={styles.cat}>
                <span className={styles.circle}>
                  <CategorySVG />
                </span>
                <p className={styles.catName}>{categories?.industry?.name}</p>
              </div>
            )}
            {device.touch && (
              <AccordionTrigger
                className={styles.accordionTrigger}
                isOpen={isOpen}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(PortfolioItem);
