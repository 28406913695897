import { memo } from 'react';
import classnames from 'classnames';

import parseHtml from '@/utils/parser';

interface Props {
  className?: string;
  content?: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  textColor?: string;
  backgroundColor?: string;
  textAlign?: 'left' | 'center' | 'right';
}

function BlockHeading({ content, level, className, textColor, backgroundColor, textAlign }: Props) {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <Tag
      className={classnames(className, backgroundColor, {
        [`has-${textColor}-color`]: textColor,
        [`has-${backgroundColor}-background-color`]: backgroundColor,
        [`text-align-${textAlign}`]: textAlign
      })}
    >
      {parseHtml(content ?? '')}
    </Tag>
  );
}

export default memo(BlockHeading);
