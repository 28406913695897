import { memo } from 'react';

import CoverHero, { CoverProps } from '@/components/CoverHero/CoverHero';

export interface Props extends CoverProps {
  id: number;
}

function BlockCover(props: Props) {
  return <CoverHero {...props} limitHeight />;
}

export default memo(BlockCover);
