import { memo } from 'react';
import classnames from 'classnames';

import styles from './Quote.module.scss';

import { cleanupHTMLTags } from '@/utils/basic-functions';

import { QuoteSVG } from '@/assets/svgs';

export type Props = {
  className?: string;
  quote: string;
  author?: string;
};

function Quote({ className, quote, author }: Props) {
  if (!quote) {
    return null;
  }

  return (
    <figure className={classnames('Quote', styles.Quote, className)}>
      <blockquote>
        <QuoteSVG className={classnames(styles.svg, styles.open)} />
        <p className={styles.quote} data-no-animation>
          {cleanupHTMLTags(quote)}
        </p>
        <QuoteSVG className={classnames(styles.svg, styles.close)} />
      </blockquote>
      {author && <figcaption>{cleanupHTMLTags(author)}</figcaption>}
    </figure>
  );
}

export default memo(Quote);
