import { memo, MutableRefObject, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './FilterBar.module.scss';

import { hasScrollableContent } from '@/utils/basic-functions';

export type Props = {
  className?: string;
  isLeftAligned?: boolean;
  onItemClick: Function;
  activeItem: string;
  mainItem?: string;
  mainItemName?: string;
  items: {
    name: string;
    count: number;
    id: number;
  }[];
};

function FilterBar({
  className,
  isLeftAligned = false,
  onItemClick,
  activeItem,
  mainItem,
  mainItemName,
  items
}: Props) {
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [isScrollable, setIsScrollable] = useState(false);
  const firstItem = items?.find((item) => {
    return item.name === mainItem;
  });
  const otherItems = items?.filter((item) => {
    return item.name !== mainItem;
  });

  useEffect(() => {
    if (containerRef.current) {
      const hasScroll = hasScrollableContent(containerRef.current, 'horizontal');
      setIsScrollable(hasScroll);
    }
  }, []);

  return (
    <div
      className={classnames(styles.FilterBar, className, {
        [styles.isLeftAligned]: isLeftAligned,
        [styles.isScrollable]: isScrollable
      })}
    >
      <div className={styles.filterWrapper}>
        <div className={styles.spacer} />
        <div className={styles.groups} ref={containerRef}>
          {firstItem && (
            <button
              className={classnames(styles.category, {
                [styles.active]: activeItem === firstItem?.name
              })}
              onClick={(e) => onItemClick(e, firstItem?.name, firstItem?.id)}
            >
              <span>{mainItemName ? mainItemName : firstItem?.name}</span>
              <span className={styles.count}>{firstItem?.count}</span>
            </button>
          )}
          {otherItems?.map((item, index: number) => {
            return (
              <button
                className={classnames(styles.category, {
                  [styles.active]: activeItem === item.name
                })}
                key={index}
                onClick={(e) => onItemClick(e, item.name, item.id)}
              >
                <span>{item.name}</span>
                <span className={styles.count}>{item.count}</span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default memo(FilterBar);
