import { memo } from 'react';

import Separator from '@/components/Separator/Separator';

export type Props = {
  className?: string;
};

function BlockSeparator({ className }: Props) {
  return <Separator className={className} />;
}

export default memo(BlockSeparator);
