// @ts-nocheck TODO: fix typescript when fetching graphql data
import { memo, useEffect, useState } from 'react';
import classnames from 'classnames';
import useSWR from 'swr';

import styles from './TeamHandler.module.scss';

import { fetcher, fetchTeamEndpoint } from '@/graphql/fetcher';

import TeamGrid from '../TeamGrid/TeamGrid';

export type Props = {
  className?: string;
};

function TeamHandler({ className }: Props) {
  const [teamMembers, setTeamMembers] = useState(false);

  const { data, error } = useSWR(fetchTeamEndpoint, fetcher);

  useEffect(() => {
    if (data) {
      const teamMembers = data?.teamMembers?.nodes?.map((teamMember) => ({
        name: teamMember?.title,
        category: teamMember?.team?.teamType,
        image: {
          src: teamMember?.team?.gridImage?.sourceUrl,
          alt: teamMember?.team?.gridImage?.altText,
          width: teamMember?.team?.gridImage?.mediaDetails?.width,
          height: teamMember?.team?.gridImage?.mediaDetails?.height
        },
        position: teamMember?.team?.title,
        url: teamMember?.uri
      }));

      setTeamMembers(teamMembers);
    }
  }, [data]);

  if (error) {
    return null;
  }

  return (
    <div className={classnames(styles.TeamHandler, className)}>
      {teamMembers && <TeamGrid teamMembers={teamMembers} />}
    </div>
  );
}

export default memo(TeamHandler);
