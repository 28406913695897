import { memo, MutableRefObject, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './PortfolioFiltersAccordion.module.scss';

import LockBodyScrollService from '@/services/lock-body-scroll';
import useLayout from '@/hooks/use-layout';

import { CheckmarkSVG } from '@/assets/svgs';

import Accordion from '../Accordion/Accordion';
import AccordionItem, { AccordionHandle } from '../AccordionItem/AccordionItem';

export type Props = {
  className?: string;
  categories?: { [key: string]: { slug: string; name: string }[] };
  handleMobileFiltersExpand: (arg0: boolean) => void;
  handleSelectedClick: (category: string, selected: string) => void;
  handleClearFiltersClick: () => void;
  result?: number;
  selectedFilters: { [key: string]: string[] };
  selectedFiltersNumber: number;
};

function PortfolioFiltersAccordion({
  className,
  categories,
  result,
  handleMobileFiltersExpand,
  handleSelectedClick,
  selectedFilters,
  selectedFiltersNumber,
  handleClearFiltersClick
}: Props) {
  const { mobile } = useLayout();
  const mobileFiltersRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<AccordionHandle[]>([]);
  const mobileFiltersTL = useRef() as MutableRefObject<GSAPTimeline>;
  const [isClosed, setClosed] = useState(true);

  useEffect(() => {
    !isClosed && handleMobileFiltersExpand(false);
  }, [isClosed, handleMobileFiltersExpand]);

  useEffect(() => {
    let q = gsap.utils.selector(mobileFiltersRef);
    mobileFiltersTL.current && mobileFiltersTL.current.progress(0).kill();

    mobile &&
      mobileFiltersRef.current &&
      (mobileFiltersTL.current = gsap
        .timeline({
          onStart: () => LockBodyScrollService.lock(),
          onReverseComplete: function () {
            setClosed(false);
          }
        })
        // accordion
        .set(mobileFiltersRef.current!.children[1], {
          autoAlpha: 1
        })
        // overlay
        .to(mobileFiltersRef.current!.children[0], {
          y: 0,
          duration: 0.634,
          ease: 'ease1'
        })
        .from(
          q('.filterByInside'),
          {
            autoAlpha: 0,
            duration: 0.3,
            ease: 'linear'
          },
          '-=0.5'
        )
        .add(
          itemRef.current.map((item) => item && item.animateIn()),
          '-=0.3'
        )).from(
        q('.buttons'),
        {
          autoAlpha: 0,
          duration: 0.3,
          ease: 'linear'
        },
        '-=0.6'
      );
  }, [mobileFiltersRef, mobile]);

  function onCloseClick() {
    LockBodyScrollService.unlock();
    mobileFiltersTL.current !== undefined && mobileFiltersTL.current.reversed(true);
  }

  return (
    <div className={classnames(styles.filtersAccordion, className)} ref={mobileFiltersRef}>
      <div className={styles.overlay} />
      <div className={styles.accordion}>
        <div className={classnames(styles.filterByInside, 'filterByInside')} onClick={onCloseClick}>
          Filter By
          <button className={styles.close}>
            <span className={styles.line} />
            <span className={styles.line} />
          </button>
        </div>

        <Accordion className={styles.accordionSection}>
          {categories &&
            Object.keys(categories).map((key, index) => (
              <AccordionItem
                delay={index * 0.1}
                ref={(el: AccordionHandle) => (itemRef.current[index] = el)}
                className={classnames(styles.filterList, styles.mobileFilterList)}
                key={index}
                isInitiallyOpen={false}
                selectedNumber={selectedFilters[key].length}
                animateScroll={false}
                title={key.charAt(0).toUpperCase() + key.slice(1)}
                body={categories![key].map((cat, index) => {
                  return (
                    <li
                      key={index}
                      className={classnames(styles.filterItem, {
                        [styles.active]:
                          selectedFilters.hasOwnProperty(key) &&
                          selectedFilters[key].includes(cat.slug)
                      })}
                      data-filter={cat.slug}
                      onClick={() => {
                        handleSelectedClick(key, cat.slug);
                      }}
                    >
                      <span className={styles.circle}>
                        <CheckmarkSVG className={styles.svg} />
                      </span>
                      {cat.name}
                    </li>
                  );
                })}
              />
            ))}
        </Accordion>
        <div
          className={classnames(styles.buttons, 'buttons', {
            [styles.disabled]: selectedFiltersNumber <= 0
          })}
        >
          <div className={styles.button} onClick={onCloseClick}>
            Show Results<span className={styles.number}>{result}</span>
          </div>
          <div
            className={styles.button}
            onClick={() => {
              handleClearFiltersClick();
            }}
          >
            Clear Filters
            <button className={styles.close}>
              <span className={styles.line} />
              <span className={styles.line} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(PortfolioFiltersAccordion);
