// @ts-nocheck TODO: fix typescript errors
import { memo, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import classnames from 'classnames';

import styles from './PostWithFilterHandler.module.scss';

import LoadingItem from '@/components/LoadingItem/LoadingItem';
import Pagination from '@/components/Pagination/Pagination';
import PostGrid from '@/components/PostGrid/PostGrid';
import PostItem, { PostItemProps } from '@/components/PostItem/PostItem';
import PostItemsFilter from '@/components/PostWithFilterHandler/PostItemsFilter';

import { calculatePageOffset, htmlToMinutesToRead } from '@/utils/basic-functions';

import { client } from '@/client';

export type Props = {
  className?: string;
  query: {
    perPage: string;
    taxQuery: {
      category: [];
      post_tag: [];
    };
    orderBy: string;
    order: string;
  };
};

function PostWithFilterHandler({ className, query }: Props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemFilter, setItemFilter] = useState(0);
  const [offsetPagination, setOffsetPagination] = useState(0);

  const [queryArgs, setQueryArgs] = useState({
    where: {
      offsetPagination: {
        size: parseInt(query?.perPage),
        offset: 0
      },
      orderby: {
        fields: {
          field: query.orderBy.toUpperCase(),
          order: query.order.toUpperCase()
        }
      }
    }
  });

  const { useQuery } = client;
  const queryObj = useQuery();
  const posts = queryObj.posts({
    first: parseInt(query?.perPage),
    ...queryArgs
  });

  const isLoading = queryObj.$state.isLoading;

  useEffect(() => {
    if (posts?.pageInfo?.offsetPagination?.total) {
      setOffsetPagination(parseInt(posts?.pageInfo?.offsetPagination?.total));
    }
  }, [posts?.pageInfo?.offsetPagination?.total]);

  useEffect(() => {
    setQueryArgs((prevQuery) => ({
      where: {
        ...prevQuery.where,
        offsetPagination: {
          ...prevQuery.where.offsetPagination,
          offset: itemOffset
        }
      }
    }));
  }, [itemOffset]);

  useEffect(() => {
    setQueryArgs((prevQuery) => ({
      where: {
        ...prevQuery.where,
        offsetPagination: {
          ...prevQuery.where.offsetPagination,
          offset: 0 // Reset to page 1
        },
        categoryIn: itemFilter === 0 ? [] : itemFilter
      }
    }));
  }, [itemFilter]);

  const handlePageClick = (event) => {
    // const element = document.getElementById('post-grid');
    // if (element) {
    //   element.scrollIntoView({ behavior: 'smooth' });
    // }

    const newOffset = calculatePageOffset(event.selected + 1, query?.perPage);
    unstable_batchedUpdates(() => {
      setItemOffset(newOffset);
      setCurrentPage(event.selected);
    });
  };

  const handleFilterClick = (event, itemID) => {
    unstable_batchedUpdates(() => {
      setItemFilter(itemID);
      setCurrentPage(0);
    });
  };

  const pageCount = Math.ceil(offsetPagination / parseInt(query?.perPage ?? 0));

  const postItems = posts?.nodes.map((post) => {
    const featuredImage = post?.featuredImage?.node;
    return {
      date: post?.date,
      title: post?.title(),
      link: post?.uri,
      featuredImage: {
        src: featuredImage?.sourceUrl(),
        alt: featuredImage?.altText,
        width: featuredImage?.mediaDetails?.width,
        height: featuredImage?.mediaDetails?.height
      },
      minutesToRead: htmlToMinutesToRead(post?.content() ?? ''),
      categories: post?.categories()?.nodes?.map((node) => node?.name || '')
    };
  });

  const emptyPostItems = useMemo(() => {
    return Array.from({ length: query?.perPage ?? 8 }).fill({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="post-grid" className={classnames(styles.PostWithFilterHandler, className)}>
      <PostItemsFilter handleClick={handleFilterClick} activeFilter={itemFilter} />
      <PostGrid disableVariableGrid>
        {isLoading && emptyPostItems?.map((_, index) => <LoadingItem key={index} />)}
        {!isLoading &&
          postItems?.map((post: PostItemProps, index: number) => (
            <PostItem {...post} key={index} isInternalLink={true} />
          ))}
      </PostGrid>
      {pageCount !== 0 ? (
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          forcePage={currentPage}
        />
      ) : null}
    </div>
  );
}

export default memo(PostWithFilterHandler);
