// @ts-nocheck TODO: fix typescript errors
import { memo } from 'react';
import classnames from 'classnames';

import styles from './PostHandler.module.scss';

import PostItem, { PostItemProps } from '@/components/PostItem/PostItem';

import { htmlToMinutesToRead } from '@/utils/basic-functions';

import { client } from '@/client';

import PostGrid from '../PostGrid/PostGrid';

export type Props = {
  className?: string;
  query: {
    perPage: string;
    search: string;
    taxQuery: {
      category: string[] | undefined;
      post_tag: string[] | undefined;
    };
    orderBy: string;
    order: string;
    selected: [];
    notIn: string | undefined;
  };
};

function PostHandler({ className, query }: Props) {
  const { usePosts } = client;

  const posts = usePosts({
    first: parseInt(query?.perPage),
    where: {
      search: query?.search,
      categoryIn: query?.taxQuery?.category,
      tagIn: query?.taxQuery?.post_tag,
      orderby: {
        fields: {
          field: query.orderBy.toUpperCase(),
          order: query.order.toUpperCase()
        }
      },
      in: query?.selected ?? [],
      notIn: query?.notIn ?? ''
    }
  });

  const postItems = posts?.nodes.map((post) => {
    const featuredImage = post?.featuredImage?.node;
    return {
      date: post?.date,
      title: post?.title(),
      link: post?.uri,
      featuredImage: {
        src: featuredImage?.sourceUrl(),
        alt: featuredImage?.altText,
        width: featuredImage?.mediaDetails?.width,
        height: featuredImage?.mediaDetails?.height
      },
      minutesToRead: htmlToMinutesToRead(post?.content() ?? ''),
      categories: post?.categories()?.nodes?.map((node) => node?.name || '')
    };
  });

  return (
    <div className={classnames(styles.PostHandler, className)}>
      <PostGrid>
        {postItems?.map((post: PostItemProps, index: number) => (
          <PostItem {...post} key={index} isWide={postItems?.length === 1} isInternalLink={true} />
        ))}
      </PostGrid>
    </div>
  );
}

export default memo(PostHandler);
