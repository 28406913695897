import { memo } from 'react';
import classnames from 'classnames';

import styles from './Spacer.module.scss';

export type Props = {
  height?: string;
};

function Spacer({ height = '100px' }: Props) {
  return (
    <div
      className={classnames(styles.Spacer)}
      style={{ '--spacer-height': height } as React.CSSProperties}
      aria-hidden="true"
    />
  );
}

export default memo(Spacer);
