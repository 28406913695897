import { memo } from 'react';
import ReactPaginate from 'react-paginate';
import classnames from 'classnames';

import styles from './Pagination.module.scss';

export type Props = {
  className?: string;
  pageCount: number;
  handlePageClick: () => void;
  forcePage: number;
};

function Pagination({ className, handlePageClick, pageCount, forcePage }: Props) {
  return (
    <div className={classnames(styles.Pagination, className)}>
      <ReactPaginate
        onPageChange={handlePageClick}
        pageRangeDisplayed={4}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel="Prev"
        nextLabel="Next"
        containerClassName="pagination"
        pageClassName="pagination-page-container"
        pageLinkClassName="pagination-page-link"
        previousClassName={classnames('pagination-labels', 'pagination-previous-item')}
        nextClassName={classnames('pagination-labels', 'pagination-next-item')}
        breakLabel="..."
        disabledClassName="pagination-disabled"
        breakClassName="pagination-break"
        activeClassName="pagination-active"
        renderOnZeroPageCount={() => null}
        forcePage={forcePage}
      />
    </div>
  );
}

export default memo(Pagination);
