// @ts-nocheck TODO: fix typescript errors
import { memo } from 'react';

import BlogItemHandler from '@/components/BlogItemHandler/BlogItemHandler';
import CompanyHandler from '@/components/CompanyHandler/CompanyHandler';
import PortfolioGrid from '@/components/PortfolioGrid/PortfolioGrid';
import PostHandler from '@/components/PostHandler/PostHandler';
import PostWithFilterHandler from '@/components/PostWithFilterHandler/PostWithFilterHandler';
import PressItemHandler from '@/components/PressItemHandler/PressItemHandler';
import PressWithFilterHandler from '@/components/PressWithFilterHandler/PressWithFilterHandler';
import TeamHandler from '@/components/TeamHandler/TeamHandler';

interface Props {
  query: {
    perPage?: string;
    taxQuery?:
      | {
          category?: [] | undefined;
          post_tag?: [] | undefined;
        }
      | undefined;
    orderBy?: string;
    order?: string;
    postType?: string;
  };
}

function BlockQueryHandler({ query }: Props) {
  switch (query?.postType) {
    case 'post':
      if (query?.perPage === '8') {
        return <PostWithFilterHandler query={query} />;
      } else if (query?.perPage === '1') {
        return <BlogItemHandler query={query} />;
      } else {
        return <PostHandler query={query} />;
      }
    case 'company':
      if (query?.perPage === '4') {
        return <CompanyHandler query={query} />;
      } else {
        return <PortfolioGrid query={query} />;
      }
    case 'press':
      if (query?.perPage === '1') {
        return <PressItemHandler query={query} />;
      } else {
        return <PressWithFilterHandler query={query} />;
      }
    case 'page':
      return <div>Page - Handler</div>;
    case 'team':
      return <TeamHandler query={query} />;
    default:
      return <div>No handler for query type: {query?.postType ?? 'empty'}</div>;
  }
}

export default memo(BlockQueryHandler);
