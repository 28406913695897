import { memo, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';
import Swiper, { Controller } from 'swiper';

import styles from './Carousel.module.scss';

import AsyncImage from '@/components/AsyncImage/AsyncImage';
import Image, { imageLayoutSizes } from '@/components/Image/Image';
import Separator from '@/components/Separator/Separator';
import SliderButton from '@/components/SliderButton/SliderButton';
import ThemedButton from '@/components/ThemedButton/ThemedButton';

import sanitizer from '@/utils/sanitizer';

export type CarouselList = {
  img: {
    src: string;
    alt: string;
  };
  logo: {
    src: string;
    alt: string;
  };
  title: string;
  descriptionArray: string[];
  link: string;
  linkTarget?: string;
}[];

export type Props = {
  className?: string;
  list: CarouselList;
};

function Carousel({ className, list }: Props) {
  const firstCarouselRef = useRef<HTMLDivElement>(null);
  const secondCarouselRef = useRef<HTMLDivElement>(null);
  const firstSwiperRef = useRef<Swiper | null>(null);
  const secondSwiperRef = useRef<Swiper | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [activeIndex, setActiveIndex] = useState(0);

  function handleNextSlide() {
    firstSwiperRef.current?.slideNext();
  }

  function handlePrevSlide() {
    firstSwiperRef.current?.slidePrev();
  }

  useEffect(() => {
    firstSwiperRef.current = new Swiper(firstCarouselRef.current!, {
      modules: [Controller],
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 667,
      allowTouchMove: true,
      virtualTranslate: false,
      grabCursor: true,
      on: {
        slideChange: (swiper) => {
          setActiveIndex(swiper.activeIndex);
        }
      },
      breakpoints: {
        768: {
          allowTouchMove: false,
          virtualTranslate: true,
          grabCursor: false
        }
      }
    });

    secondSwiperRef.current = new Swiper(secondCarouselRef.current!, {
      modules: [Controller],
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 667,
      grabCursor: true,
      enabled: false,
      breakpoints: {
        768: {
          enabled: true
        }
      }
    });

    firstSwiperRef.current.controller.control = secondSwiperRef.current;
    secondSwiperRef.current.controller.control = firstSwiperRef.current;

    return () => {
      firstSwiperRef.current?.destroy();
      secondSwiperRef.current?.destroy();
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const timeline = gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 70%'
        }
      })
      .setupFadeIn(containerRef.current, { y: 0 }, 0)
      .fadeIn(containerRef.current, {}, 0.3);

    return () => {
      timeline?.scrollTrigger?.kill();
      timeline?.kill();
    };
  }, []);

  return (
    <div className={classnames(styles.Carousel, className)} ref={containerRef}>
      <div className={styles.leftContainer}>
        <Separator isTopAbsolute className={styles.topBorder} />
        <SliderButton
          className={styles.sliderButton}
          controls={{
            onNextSlide: handleNextSlide,
            onPreviousSlide: handlePrevSlide,
            activeSlide: activeIndex + 1,
            totalSlides: list.length
          }}
        />
        <div
          className={classnames('swiper', styles.swiper, styles.firstCarousel)}
          ref={firstCarouselRef}
        >
          <div className={classnames('swiper-wrapper', styles.wrapper)}>
            {list.map((data, index) => {
              return (
                <div className={classnames('swiper-slide', styles.slide)} key={index}>
                  {Boolean(data.title) ? (
                    <h3
                      className={styles.title}
                      dangerouslySetInnerHTML={{ __html: sanitizer(data.title) }}
                    />
                  ) : null}

                  {Boolean(data.img.src) ? (
                    <Image
                      className={styles.imageMobile}
                      src={data.img.src}
                      srcWidths={imageLayoutSizes.halfBlockWidth}
                      alt={data.img.alt}
                    />
                  ) : null}

                  {Boolean(data.logo.src) && (
                    <AsyncImage
                      src={data.logo.src}
                      className={styles.imageLogo}
                      alt={data.logo.alt}
                    />
                  )}

                  {data.descriptionArray?.map((description, index) => {
                    return (
                      <p
                        className={styles.description}
                        key={index}
                        dangerouslySetInnerHTML={{ __html: sanitizer(description) }}
                      />
                    );
                  })}

                  {Boolean(data.link) ? (
                    <ThemedButton
                      className={styles.btn}
                      text={'Read More'}
                      url={data.link}
                      linkTarget={data.linkTarget}
                    />
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
        <Separator isBottomAbsolute className={styles.bottomBorder} />
      </div>
      <div className={classnames(styles.swiper, styles.secondCarousel)} ref={secondCarouselRef}>
        <div className={classnames('swiper-wrapper', styles.wrapper)}>
          {list.map((data, index) => {
            return (
              <div className={classnames('swiper-slide', styles.slide)} key={index}>
                <Image
                  className={styles.imageDesktop}
                  src={data.img.src}
                  srcWidths={imageLayoutSizes.halfBlockWidth}
                  alt={data.img.alt}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default memo(Carousel);
