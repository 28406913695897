import { memo } from 'react';

import BackButton from '@/components/BackButton/BackButton';
import ScrollButton from '@/components/ScrollButton/ScrollButton';
import ThemedButton, { ButtonType } from '@/components/ThemedButton/ThemedButton';

const MapGutenbergToButtonProps = {
  'is-style-primary': ButtonType.Primary,
  'is-style-secondary': ButtonType.Secondary,
  'is-style-action': ButtonType.Action,
  'is-style-link-icon': ButtonType.Icon
};

const ScrollButtonType = 'is-style-navigation';
const BackButtonType = 'is-style-back';

interface Props {
  className?: string;
  url?: string;
  content: string;
}

function BlockButton(props: Props) {
  const { className, url, content } = props;

  const themeMatch =
    Object.keys(MapGutenbergToButtonProps).find((key) => className?.includes(key)) ||
    ButtonType.Primary;
  const theme = MapGutenbergToButtonProps[themeMatch as keyof typeof MapGutenbergToButtonProps];

  if (className?.includes(ScrollButtonType)) {
    return <ScrollButton text={content} />;
  } else if (className?.includes(BackButtonType)) {
    return <BackButton text={content} url={url} />;
  }

  return <ThemedButton {...props} text={content} theme={theme}></ThemedButton>;
}

export default memo(BlockButton);
