import { memo } from 'react';

import Gallery from '@/components/Gallery/Gallery';

import { Block } from '@/client';

export type Props = {
  className?: string;
  innerBlocks?: Block[];
};

function BlockGallery({ className, innerBlocks }: Props) {
  // @ts-ignore
  const images = innerBlocks?.map(({ attributes }) => ({
    alt: attributes?.alt,
    url: attributes?.url,
    sizes: attributes?.sizes,
    caption: attributes?.caption
  }));

  return <Gallery className={className} images={images} />;
}

export default memo(BlockGallery);
