import { memo, MutableRefObject, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './ScrollButton.module.scss';

import { ArrowThinSVG } from '@/assets/svgs';

export type Props = {
  className?: string;
  text?: string;
};

function ScrollButton({ className, text = 'Scroll To Explore' }: Props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const scrollRef = useRef() as MutableRefObject<HTMLElement>;

  function handleClick() {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  useEffect(() => {
    const scrollElement = document.getElementById('scroll-to');

    if (scrollElement) {
      setIsDisabled(false);
      scrollRef.current = scrollElement;
    } else {
      setIsDisabled(true);
    }
  }, []);

  return (
    <button
      className={classnames('ScrollButton', styles.ScrollButton, className)}
      onClick={handleClick}
      disabled={isDisabled}
    >
      <span className={styles.circle}>
        <ArrowThinSVG className={styles.svg} />
      </span>
      {text}
    </button>
  );
}

export default memo(ScrollButton);
