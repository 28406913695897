import { memo } from 'react';

import Accordion from '@/components/Accordion/Accordion';
import AccordionItem from '@/components/AccordionItem/AccordionItem';

import { Block } from '@/client';

export type Props = {
  className?: string;
  innerBlocks?: Block[];
};

function BlockAccordion({ className, innerBlocks }: Props) {
  return (
    <>
      {innerBlocks?.length !== 0 && (
        <Accordion className={className}>
          {/** @ts-ignore */}
          {innerBlocks?.map(({ attributes, innerBlocks }, index) => (
            <AccordionItem
              key={index}
              innerBlocks={innerBlocks}
              isInitiallyOpen={index === 0}
              {...attributes}
            />
          ))}
        </Accordion>
      )}
    </>
  );
}

export default memo(BlockAccordion);
