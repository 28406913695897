import { memo, useEffect, useRef } from 'react';
import classnames from 'classnames';

import styles from './FreeForm.module.scss';

import sanitizer from '@/utils/sanitizer';

export type Props = {
  className?: string;
  content?: string;
};

function FreeForm({ className, content }: Props) {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    const iframes = contentRef?.current.querySelectorAll('iframe');

    iframes?.forEach((iframe) => {
      const width = iframe.getAttribute('width');
      const height = iframe.getAttribute('height');

      if (width && height) {
        iframe.style.setProperty('--aspect-ratio', `${width}/${height}`);
        iframe.style.setProperty('height', 'auto');
      }
    });
  }, []);

  if (!content) {
    return null;
  }

  return (
    <div className={classnames(styles.FreeForm, className)}>
      <div className={styles.content} ref={contentRef}>
        <div dangerouslySetInnerHTML={{ __html: sanitizer(content) }} />
      </div>
    </div>
  );
}

export default memo(FreeForm);
