import { memo } from 'react';

import { Props as ImageProps } from '@/blocks/BlockImage/BlockImage';
import VideoEmbed from '@/components/VideoEmbed/VideoEmbed';

export type Props = {
  url: string;
  caption: string;
  type: string;
  providerNameSlug: string;
  allowResponsive: boolean;
  responsive: boolean;
  previewable: boolean;
  posterObj: ImageProps;
};

function BlockEmbed({ url, posterObj, providerNameSlug }: Props) {
  return <VideoEmbed url={url} poster={posterObj} type={providerNameSlug}></VideoEmbed>;
}

export default memo(BlockEmbed);
