import { memo, MutableRefObject, useEffect, useRef } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './ImageWithLogo.module.scss';

import AsyncImage from '@/components/AsyncImage/AsyncImage';
import Image, { imageLayoutSizes } from '@/components/Image/Image';

export type Props = {
  className?: string;
  img: {
    src: string;
    alt: string;
  };
  logo?: string;
};

function ImageWithLogo({ img, logo }: Props) {
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const imageRef = useRef<HTMLImageElement>(null);
  const logoRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const timeline = gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 70%'
        }
      })
      .setupFadeIn([imageRef.current, logoRef.current], { y: 0 }, 0)
      .fadeIn(imageRef.current, {}, 0.3)
      .fadeIn(logoRef.current, {}, 0.6);

    return () => {
      timeline?.scrollTrigger?.kill();
      timeline?.kill();
    };
  }, []);

  return (
    <div className={classnames(styles.ImageWithLogo)} ref={containerRef}>
      <Image
        ref={imageRef}
        className={styles.image}
        src={img.src}
        alt={img.alt}
        srcWidths={imageLayoutSizes.halfBlockWidth}
      />
      <AsyncImage className={styles.logo} src={logo} alt="" ref={logoRef} />
    </div>
  );
}

export default memo(ImageWithLogo);
