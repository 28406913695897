import { MutableRefObject, useEffect, useState } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useOutsideAlerter(refs: MutableRefObject<HTMLElement[]>) {
  const [activeFilter, setActiveFilter] = useState<Number>(-1);

  useEffect(() => {
    let clickedFilter = -1;
    function handleClickOutside(event: TouchEvent | MouseEvent | KeyboardEvent, inside = true) {
      refs.current.every((ref: HTMLElement, index: number) => {
        if (
          inside &&
          ref &&
          (ref.contains(event.target as Node) || ref.nextSibling?.contains(event.target as Node))
        ) {
          // clicked on filter
          clickedFilter = index;
          return false;
        } else {
          // clicked outside filter
          clickedFilter = -1;
          return true;
        }
      });
      setActiveFilter(clickedFilter);
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', (e) => {
      e.code === 'Space' || (e.code === 'Enter' && handleClickOutside(e));
      e.code === 'Escape' && handleClickOutside(e, false);
    });
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs]);

  return { activeFilter };
}
