import { memo, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './ButtonGroup.module.scss';

export type Props = PropsWithChildren<{
  className?: string;
  justifyContent?: 'left' | 'center' | 'right';
  orientation?: 'horizontal' | 'vertical';
}>;

function ButtonGroup({ className, children, justifyContent, orientation }: Props) {
  return (
    <div
      className={classnames(
        styles.ButtonGroup,
        className,
        orientation && styles[orientation],
        justifyContent && styles[justifyContent]
      )}
    >
      {children}
    </div>
  );
}

export default memo(ButtonGroup);
