import { memo, useEffect, useRef } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './ListSlotMachine.module.scss';

import sanitizer from '@/utils/sanitizer';

export type Props = {
  className?: string;
  duration?: number;
  pause?: number;
  list: string[];
};

function ListSlotMachine({ className, duration = 0.5, pause = 0.534, list = [] }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const q = gsap.utils.selector(listRef);
    const elsToAnimate = q('li:not([aria-hidden="true"])');

    if (!elsToAnimate.length) {
      return;
    }

    let ctx = gsap.context(() => {
      const targets = elsToAnimate.map((element) => element);
      const numberOfTargets = elsToAnimate.length;
      const stagger = duration + pause;
      const repeatDelay = stagger * (numberOfTargets - 1) + pause;

      gsap
        .timeline({
          repeat: -1,
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top 70%',
            toggleActions: 'play pause resume pause'
          }
        })
        .slotMachineIn(
          targets,
          {
            duration: duration,
            stagger: {
              each: stagger,
              repeat: -1,
              repeatDelay: repeatDelay
            }
          },
          0
        )
        .slotMachineOut(
          targets,
          {
            duration: duration,
            stagger: {
              each: stagger,
              repeat: -1,
              repeatDelay: repeatDelay
            }
          },
          stagger
        );
    });

    return () => {
      ctx?.revert();
      ctx?.kill();
    };
  }, [duration, pause, list]);

  return (
    <div className={classnames(styles.ListSlotMachine, className)} ref={containerRef}>
      <ul className={styles.list} ref={listRef}>
        <li aria-hidden={true} key={0}>
          Spacing
        </li>

        {list?.map((key, index) => (
          <li key={index + 1} dangerouslySetInnerHTML={{ __html: sanitizer(key) }}></li>
        ))}
      </ul>
    </div>
  );
}

export default memo(ListSlotMachine);
