// @ts-nocheck TODO: fix typescript errors
import { memo, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import classnames from 'classnames';

import styles from './PressWithFilterHandler.module.scss';

import LoadingItem from '@/components/LoadingItem/LoadingItem';
import Pagination from '@/components/Pagination/Pagination';
import PostGrid from '@/components/PostGrid/PostGrid';
import PostItem, { PostItemProps } from '@/components/PostItem/PostItem';
import PostItemsFilter from '@/components/PressWithFilterHandler/PressItemsFilter';

import { calculatePageOffset } from '@/utils/basic-functions';

import { client, TaxonomyEnum } from '@/client';

export type Props = {
  className?: string;
  query: {
    perPage: string;
    taxQuery: {
      category: [];
      post_tag: [];
    };
    orderBy: string;
    order: string;
  };
};

function PressWithFilterHandler({ className, query }: Props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemFilter, setItemFilter] = useState(0);
  const [offsetPagination, setOffsetPagination] = useState(0);

  const [queryArgs, setQueryArgs] = useState({
    where: {
      offsetPagination: {
        size: parseInt(query?.perPage),
        offset: 0
      },
      orderby: {
        fields: {
          field: query.orderBy.toUpperCase(),
          order: query.order.toUpperCase()
        }
      }
    }
  });

  const { useQuery } = client;
  const queryObj = useQuery();
  const posts = queryObj.pressPosts({
    first: parseInt(query?.perPage),
    ...queryArgs
  });

  const isLoading = queryObj.$state.isLoading;

  useEffect(() => {
    if (posts?.pageInfo?.offsetPagination?.total) {
      setOffsetPagination(parseInt(posts?.pageInfo?.offsetPagination?.total));
    }
  }, [posts?.pageInfo?.offsetPagination?.total]);

  useEffect(() => {
    setQueryArgs((prevQuery) => ({
      where: {
        ...prevQuery.where,
        offsetPagination: {
          ...prevQuery.where.offsetPagination,
          offset: itemOffset
        }
      }
    }));
  }, [itemOffset]);

  useEffect(() => {
    if (itemFilter === 0) {
      setQueryArgs((prevQuery) => ({
        where: {
          ...prevQuery.where,
          offsetPagination: {
            ...prevQuery.where.offsetPagination,
            offset: 0 //Reset to page 1
          },
          taxQuery: []
        }
      }));
    } else {
      setQueryArgs((prevQuery) => ({
        where: {
          ...prevQuery.where,
          taxQuery: {
            taxArray: {
              fields: {
                field: 'SLUG',
                taxonomy: TaxonomyEnum.PRESSCATEGORY,
                terms: [itemFilter]
              }
            }
          },
          offsetPagination: {
            ...prevQuery.where.offsetPagination,
            offset: 0 //Reset to page 1
          }
        }
      }));
    }
  }, [itemFilter]);

  const handlePageClick = (event) => {
    // const element = document.getElementById('press-grid');
    // if (element) {
    //   element.scrollIntoView({ behavior: 'smooth' });
    // }

    const newOffset = calculatePageOffset(event.selected + 1, query?.perPage);
    unstable_batchedUpdates(() => {
      setItemOffset(newOffset);
      setCurrentPage(event.selected);
    });
  };

  const handleFilterClick = (event, itemID) => {
    unstable_batchedUpdates(() => {
      setItemFilter(itemID);
      setCurrentPage(0);
    });
  };

  const pageCount = Math.ceil(offsetPagination / parseInt(query?.perPage ?? 0));

  const pressItems = posts?.nodes.map((post) => {
    const categories = post?.pressCategories()?.nodes?.map((node) => node?.name || '');
    const isPortfolio = categories?.includes('Portfolio');
    const featuredImage = post?.featuredImage?.node;

    return {
      date: post?.date,
      title: post?.title(),
      link: post?.acfPress?.externalLink,
      featuredImage: {
        src: featuredImage?.sourceUrl(),
        alt: featuredImage?.altText,
        width: featuredImage?.mediaDetails?.width,
        height: featuredImage?.mediaDetails?.height
      },
      minutesToRead: 0,
      categories,
      isPortfolio
    };
  });

  const emptyPostItems = useMemo(() => {
    return Array.from({ length: query?.perPage ?? 8 }).fill({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="press-grid" className={classnames(styles.PressWithFilterHandler, className)}>
      <PostItemsFilter handleClick={handleFilterClick} activeFilter={itemFilter} />
      <PostGrid disableVariableGrid>
        {isLoading && emptyPostItems?.map((_, index) => <LoadingItem key={index} />)}
        {pressItems?.map((post: PostItemProps, index: number) => (
          <PostItem {...post} key={index} isInternalLink={false} />
        ))}
      </PostGrid>
      {pageCount !== 0 ? (
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          forcePage={currentPage}
        />
      ) : null}
    </div>
  );
}

export default memo(PressWithFilterHandler);
