import { memo } from 'react';

import type { CarouselList } from '@/components/Carousel/Carousel';
import Carousel from '@/components/Carousel/Carousel';

import { Block } from '@/client';

export type Props = {
  className?: string;
  innerBlocks?: Block[];
};

function BlockCarousel({ className, innerBlocks }: Props) {
  const listProps: CarouselList = [];

  // TODO: Revisit this approach
  innerBlocks?.forEach((carouselItem, index: number) => {
    const mediaTextContainer = carouselItem?.innerBlocks?.[0];

    if (!mediaTextContainer || !mediaTextContainer?.innerBlocks) {
      return;
    }

    listProps[index] = {
      title: '',
      descriptionArray: [],
      link: '',
      linkTarget: '',
      img: {
        src: '',
        alt: ''
      },
      logo: {
        src: '',
        alt: ''
      }
    };

    // @ts-ignore
    const mediaTextAttributes = mediaTextContainer.attributes;

    listProps[index].img = {
      src: mediaTextAttributes?.mediaUrl,
      alt: mediaTextAttributes?.mediaAlt
    };

    mediaTextContainer?.innerBlocks.forEach((innerBlock) => {
      // @ts-ignore
      const { attributes, name, saveContent, originalContent } = innerBlock;
      console.log(saveContent);

      switch (name) {
        case 'core/heading':
          listProps[index].title = String(saveContent);
          break;
        case 'core/paragraph':
          listProps[index].descriptionArray = [
            ...listProps[index].descriptionArray,
            String(saveContent)
          ];

          break;
        case 'core/button':
          // get href from originalContent
          const href = originalContent?.match(/href="([^"]+)"/)?.[1];
          // get target from originalContent
          const target = originalContent?.match(/target="([^"]+)"/)?.[1];
          listProps[index].link = href ? href : '';
          listProps[index].linkTarget = target ? target : '_self';
          break;
        case 'core/image':
          listProps[index].logo.src = attributes?.url;
          listProps[index].logo.alt = attributes?.alt;
          break;
        default:
          break;
      }
    });
  });

  return <Carousel className={className} list={listProps} />;
}

export default memo(BlockCarousel);
