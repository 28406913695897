// Reference: https://github.com/kellenmace/wpgraphql-gutenberg-demo/blob/main/lib/parser.js
import Link from 'next/link';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';

import sanitizer from '@/utils/sanitizer';

export default function parseHtml(html: string) {
  const sanitizedHtml = sanitizer(html);

  const options: HTMLReactParserOptions = {
    // @ts-ignore
    replace: ({ name, attribs, children }) => {
      // Convert internal links to Next.js Link components.
      const isInternalLink = name === 'a' && attribs['data-internal-link'] === 'true';

      if (isInternalLink) {
        return (
          <Link href={attribs.href}>
            <a {...attribs}>{domToReact(children, options)}</a>
          </Link>
        );
      }
    }
  };

  return parse(sanitizedHtml, options);
}
