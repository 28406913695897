import { memo } from 'react';

import ImageWithLogo from '@/components/ImageWithLogo/ImageWithLogo';

import { Block } from '@/client';

export type Props = {
  className?: string;
  innerBlocks?: Block[];
  logo?: string;
};

function BlockImageWithLogo({ innerBlocks, logo }: Props) {
  // @ts-ignore
  const { url, alt } = innerBlocks?.[0]?.attributes;

  if (!url) {
    return null;
  }

  return <ImageWithLogo img={{ src: url, alt }} logo={logo} />;
}

export default memo(BlockImageWithLogo);
