import { memo } from 'react';

import ListSlotMachine from '@/components/ListSlotMachine/ListSlotMachine';

import { Block } from '@/client';

export type Props = {
  className?: string;
  innerBlocks?: Block[];
};

function BlockRotateText({ className, innerBlocks }: Props) {
  const list: string[] =
    innerBlocks
      // @ts-ignore
      ?.map(({ saveContent }) => {
        // cast to string
        return saveContent as string;
      })
      .filter(Boolean) ?? [];

  return (
    <>
      {list?.length !== 0 && <ListSlotMachine className={className} list={list}></ListSlotMachine>}
    </>
  );
}

export default memo(BlockRotateText);
