import { ForwardedRef, forwardRef, memo } from 'react';
import classnames from 'classnames';

import styles from './AsyncImage.module.scss';

export type Props = {
  className?: string;
  src?: string;
  alt?: string;
  loadingType?: 'lazy' | 'eager';
};

const AsyncImage = (
  { className, src = '', alt, loadingType = 'lazy' }: Props,
  ref: ForwardedRef<HTMLImageElement>
) => {
  return (
    <img
      ref={ref}
      className={classnames(styles.AsyncImage, className)}
      src={src}
      alt={alt}
      loading={loadingType}
      decoding="async"
    />
  );
};

export default memo(forwardRef(AsyncImage));
