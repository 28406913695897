import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './AudioPlayer.module.scss';

import { formatAudioTime } from '@/utils/basic-functions';

import { PauseSVG, PlaySVG, VolumeOffSVG, VolumeOnSVG } from '@/assets/svgs';

export type Props = {
  className?: string;
  src: string;
  autoPlay?: boolean;
};

function AudioPlayer({ className, src, autoPlay }: Props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [time, setTime] = useState('0:00');
  const progressBarRef = useRef<HTMLSpanElement | null>(null);

  const [audioTag, setAudioTag] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    const bar = progressBarRef.current;
    const audio = audioTag;
    const onClick = (e: MouseEvent) => {
      if (!bar || !audio) return;
      const { left, width } = bar.getBoundingClientRect();
      const mouseX = e.clientX - left;
      const targetProg = mouseX / width;
      audio.currentTime = audio.duration * targetProg;
    };
    if (bar) {
      bar?.addEventListener('click', onClick);
    }
    return () => {
      bar?.removeEventListener('click', onClick);
    };
  }, [audioTag]);

  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, [setIsPlaying]);

  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  const onProgress = useCallback(() => {
    if (!audioTag) return;
    const { currentTime: _currentTime, duration: _duration } = audioTag;

    const duration = isNaN(_duration) ? 0 : _duration;
    const currentTime = isNaN(_currentTime) ? 0 : _currentTime;

    setTime(formatAudioTime(currentTime));
    setProgress((currentTime / duration) * 100);
  }, [setTime, setProgress, audioTag]);

  const onCanPlay = useCallback(() => {
    if (!audioTag) return;
    setTime(formatAudioTime(audioTag.duration));
    audioTag.play();
  }, [setTime, audioTag]);

  const onTogglePlayback = useCallback(() => {
    if (!audioTag) return;
    if (isPlaying) {
      audioTag.pause();
    } else {
      audioTag.play();
    }
  }, [audioTag, isPlaying]);

  const totalTime = useMemo(() => {
    return formatAudioTime(audioTag?.duration || 0);
  }, [audioTag?.duration]);

  const onToggleSound = useCallback(() => {
    setIsMuted(!isMuted);
  }, [isMuted, setIsMuted]);

  useEffect(() => {
    audioTag?.addEventListener('play', onPlay);
    audioTag?.addEventListener('pause', onPause);
    audioTag?.addEventListener('timeupdate', onProgress);
    audioTag?.addEventListener('canplay', onCanPlay);
    return () => {
      audioTag?.pause();
      audioTag?.removeEventListener('play', onPlay);
      audioTag?.removeEventListener('pause', onPause);
      audioTag?.removeEventListener('progress', onProgress);
      audioTag?.removeEventListener('canplay', onCanPlay);
    };
  }, [onPlay, onPause, onProgress, onCanPlay, audioTag]);

  if (!src) {
    return null;
  }

  return (
    <div className={classnames(styles.AudioPlayer, className)}>
      <div className={styles.togglePlay} onClick={onTogglePlayback}>
        {isPlaying ? <PauseSVG className={styles.pause} /> : <PlaySVG className={styles.play} />}
      </div>
      <span className={classnames(styles.timeContainer)}>
        <span className={styles.time}>{time}</span>

        <span className={styles.divider}>/</span>
        <span className={styles.totalTime}>{totalTime}</span>
      </span>
      <span className={classnames(styles.progressBar)} ref={progressBarRef}>
        <span className={styles.totalProgress}></span>
        <span
          className={styles.progress}
          style={
            {
              '--scale': `${progress}%`
            } as React.CSSProperties
          }
        ></span>
      </span>

      <div className={styles.toggleSound} onClick={onToggleSound}>
        {isMuted ? (
          <VolumeOffSVG className={styles.volumeOff} />
        ) : (
          <VolumeOnSVG className={styles.volumeOn} />
        )}
      </div>
      <audio autoPlay={autoPlay} ref={setAudioTag} muted={isMuted} key={src}>
        <source src={src} />
      </audio>
    </div>
  );
}

export default memo(AudioPlayer);
