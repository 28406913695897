import { memo } from 'react';

import Blocks from '@/blocks/Blocks/Blocks';
import Column from '@/components/Column/Column';
import Columns from '@/components/Columns/Columns';

import { Block, CoreColumnsBlockAttributes } from '@/client';

interface Props {
  className?: string;
  innerBlocks?: {
    innerBlocks: Block[];
    attributes: CoreColumnsBlockAttributes;
  }[];
  verticalAlignment?: 'top' | 'center' | 'bottom';
  anchor?: string;
}

function BlockColumns({ className, innerBlocks, verticalAlignment, anchor }: Props) {
  return (
    <>
      {!!innerBlocks?.length && (
        <Columns
          className={className}
          columnCount={innerBlocks?.length}
          verticalAlignment={verticalAlignment}
          anchor={anchor}
        >
          {innerBlocks?.map(({ innerBlocks, attributes: { verticalAlignment } }, index: number) => {
            return (
              <Column
                key={index}
                verticalAlignment={verticalAlignment as Props['verticalAlignment']}
              >
                {innerBlocks?.length !== 0 && <Blocks blocks={innerBlocks} />}
              </Column>
            );
          })}
        </Columns>
      )}
    </>
  );
}

export default memo(BlockColumns);
