import { memo, MutableRefObject, useEffect, useRef } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './CoverHero.module.scss';

import Image, { imageLayoutSizes } from '@/components/Image/Image';
import InlineVideo from '@/components/InlineVideo/InlineVideo';

export enum BackgroundType {
  Image = 'image',
  Video = 'video'
}

export type CoverProps = {
  className?: string;
  url?: string;
  alt?: string;
  width?: number;
  height?: number;
  backgroundType?: BackgroundType;
  limitHeight?: boolean;
};

export const globalCoverClass = 'cover-hero';

function CoverHero({
  className,
  url,
  alt,
  width,
  height,
  backgroundType,
  limitHeight
}: CoverProps) {
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    document.body.classList.add(globalCoverClass);

    const timeline = gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 70%'
        }
      })
      .fadeIn(containerRef.current, {
        duration: 0.334
      });

    return () => {
      document.body.classList.remove(globalCoverClass);
      timeline?.scrollTrigger?.kill();
      timeline?.kill();
    };
  }, []);

  return (
    <div
      className={classnames(styles.CoverHero, className, globalCoverClass, {
        [styles.limitHeight]: limitHeight
      })}
      ref={containerRef}
    >
      {backgroundType === BackgroundType.Image && (
        <Image
          className={styles.image}
          src={url}
          width={width}
          height={height}
          alt={alt}
          loadingType="eager"
          srcWidths={imageLayoutSizes.viewport}
        />
      )}
      {backgroundType === BackgroundType.Video && (
        <InlineVideo
          className={styles.video}
          src={url}
          width={width}
          height={height}
          title={alt}
          autoPlay
          muted
          loop
          controls={false}
        />
      )}
    </div>
  );
}

export default memo(CoverHero);
