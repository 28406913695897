// @ts-nocheck TODO: fix typescript errors
import { memo } from 'react';
import classnames from 'classnames';

import styles from './BlogItemHandler.module.scss';

import MediaText from '@/components/MediaText/MediaText';

import { client } from '@/client';

import ThemedButton, { ButtonType } from '../ThemedButton/ThemedButton';

export type Props = {
  className?: string;
  query: {
    perPage: string;
    search: string;
    taxQuery: {
      category: [];
      post_tag: [];
    };
    orderBy: string;
    order: string;
    selected: [];
  };
};

function BlogItemHandler({ className, query }: Props) {
  const { usePosts } = client;

  const posts = usePosts({
    first: parseInt(query?.perPage),
    where: {
      categoryIn: query?.taxQuery?.category,
      tagIn: query?.taxQuery?.post_tag,
      search: query?.search,
      orderby: {
        fields: {
          field: query.orderBy.toUpperCase(),
          order: query.order.toUpperCase()
        }
      },
      in: query?.selected ?? []
    }
  });

  const postData = posts?.nodes?.[0];
  const featuredImage = postData?.featuredImage?.node;
  const blogPostImage = postData?.blogPost?.heroArticleImage;
  const postImage = blogPostImage ? blogPostImage : featuredImage;

  const postProps = {
    title: postData?.title(),
    link: postData?.uri,
    featuredImage: {
      src: postImage?.sourceUrl() ?? '',
      alt: postImage?.altText ?? '',
      type: postImage?.mediaType ?? '',
      width: postImage?.mediaDetails?.width ?? 0,
      height: postImage?.mediaDetails?.height ?? 0
    },
    author: {
      name: postData?.author?.node?.name
    }
  };

  return (
    <div
      className={classnames(styles.BlogItemHandler, className, {
        [styles.isLoading]: !(postProps && postProps?.title)
      })}
    >
      {postProps && postProps?.title ? (
        <MediaText
          className={classnames(styles.mediaText, 'no-desktop-border-top')}
          media={postProps.featuredImage}
        >
          <h4 className={styles.featured}>More From Eclipse</h4>
          <p className={styles.author}>By {postProps.author.name}</p>
          <h2 className={styles.title}>{postProps.title}</h2>
          <ThemedButton
            theme={ButtonType.Primary}
            text="Read More"
            url={postProps.link}
            j3IsInternalURL
          />
        </MediaText>
      ) : null}
    </div>
  );
}

export default memo(BlogItemHandler);
